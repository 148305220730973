import { EditorView } from 'prosemirror-view';
import { commentMarkNames } from './comments.service';
import { EditorContainersMap } from '@app/editor/services/prosemirror-editor/prosemirror.models';

export function checkAllEditorsIfMarkOfCommentExists(
  edContainers: EditorContainersMap,
  commentId: string
): boolean {
  let exists = false;
  Object.keys(edContainers).forEach((sectionid) => {
    const view = edContainers[sectionid].editorView;
    if (checkEditorIfMarkOfCommentExists(view, commentId)) {
      exists = true;
    }
  });
  return exists;
}

export function checkEditorIfMarkOfCommentExists(view: EditorView, commentId: string): boolean {
  const doc = view.state.doc;
  const docend = doc.content.size;
  let exists = false;
  doc.nodesBetween(0, docend, (node, pos, parent, i) => {
    const marks = node.marks.filter((mark) => commentMarkNames.includes(mark.type.name));
    if (marks.length > 0 && marks.find((m) => m.attrs.id == commentId)) {
      exists = true;
    }
  });
  return exists;
}
