import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import * as Y from 'yjs';

export interface EditorContainersMap {
  [key: string]: EditorContainer;
}

export interface EditorContainer {
  editorID: string;
  containerDiv: HTMLDivElement;
  editorState: EditorState;
  editorView: EditorView;
  dispatchTransaction: any;
  recreate?: boolean;
  versionData?: any;
}

export enum EditorsThatShouldBeHiddenUntilContent {
  Description = '[MM] Description',
  Diagnosis = '[MM] Diagnosis',
  Distribution = '[MM] Distribution',
  Ecology = '[MM] Ecology',
  Conservation = '[MM] Conservation',
  Biology = '[MM] Biology',
  TaxonDiscussion = '[MM] Taxon discussion',
  Notes = '[MM] Notes',
  Subsection = '[MM] Subsection',
}

export enum EditorsOptions {
  basicEditor = 'createBasicEditor',
  headEditor = 'renderDocumentHeadEditor',
  endEditor = 'renderDocumentEndEditor'
}

export interface XmlFragmentsMap {
  [key: string]: Y.XmlFragment;
}

export const editorsThatShouldBeHiddenUntilContent = Object.values(
  EditorsThatShouldBeHiddenUntilContent
);
