import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { XmlFragment } from 'yjs';

import { ServiceShare } from '@app/editor/services/service-share.service';
import { basicArticleSection } from '@app/editor/utils/interfaces/articleSection';
import { ImportJatsService } from '../importAsJatsXML.service';
import { updateYFragment } from '../../../../../y-prosemirror-src/plugins/sync-plugin.js';
import { ImportWizardDialogData, JatsModule, SectionContent } from '../jats.models';
import { ConfirmationDialogComponent } from '@app/editor/dialogs/confirmation-dialog/confirmation-dialog.component';
import { getSectionBasicStructure } from '@app/editor/utils/articleBasicStructure';
import { EditorsRefsManagerService } from '@app/layout/pages/library/lib-service/editors-refs-manager.service';
import { CitableElementsService } from '@app/editor/services/citable-elements.service';
import { YdocService } from '@app/editor/services/ydoc.service';
import { EditorContainersMap } from '@app/editor/services/prosemirror-editor/prosemirror.models';

@Component({
  selector: 'app-import-wizard-dialog',
  templateUrl: './import-wizard-modal.component.html',
  styleUrls: ['./import-wizard-modal.component.scss'],
})
export class ImportWizardDialogComponent {
  sections: SectionContent[];
  articleSections: basicArticleSection[] = this.sharedService.YdocService.articleStructureMap.get(
    'articleSectionsStructure'
  );

  shouldPreserve = false;
  isLoading = false;

  newSectionStructure: basicArticleSection[] = [];
  newSectionStructureFlat: string[] = [];

  editors: EditorContainersMap;
  xmlFragments: {
    [key: string]: XmlFragment;
  };

  constructor(
    public dialog: MatDialog,
    public sharedService: ServiceShare,
    public importJatsService: ImportJatsService,
    private editorsRefsManager: EditorsRefsManagerService,
    private citableElementsService: CitableElementsService,
    private ydocService: YdocService,
    private changeDetectionRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    public data: { from: JatsModule.article | JatsModule.dashboard; data: ImportWizardDialogData },
    private dialogRef: MatDialogRef<ImportWizardDialogComponent>
  ) {
    this.sections = this.data.data.sectionsContent;
  }

  preserveIntervalOption(): void {
    this.isLoading = true;
    this.shouldPreserve = !this.shouldPreserve;

    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }

  submitImport(): void {
    this.editors = this.sharedService.ProsemirrorEditorsService.editorContainers;
    this.xmlFragments = this.sharedService.ProsemirrorEditorsService.xmlFragments;

    if (this.data.from == JatsModule.dashboard) {
      this.setCitableElementsAndContributors();

      this.buildEditorData(false);
      this.addCitableElements();
      this.dialogRef.close(true);
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '532px',
        data: {
          title: 'Warning',
          content: 'All existing content in the document will be deleted.',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result && result.confirmed) {
          this.setCitableElementsAndContributors();

          setTimeout(() => {
            this.buildEditorData(true);
            setTimeout(() => {
              this.sharedService.YdocService.importSubject.next('render');
              setTimeout(() => {
                this.addCitableElements();
              }, 1000);
            }, 1000);
          }, 1500);
          this.dialogRef.close(true);
        }
      });
    }
  }

  findExistingSection(
    sections: basicArticleSection[],
    sectionContent: SectionContent
  ): basicArticleSection {
    let foundedSection: basicArticleSection;

    sections.forEach((s) => {
      if (!foundedSection) {
        if (s.sectionID == sectionContent.originalSecID) {
          foundedSection = s;
        }

        if (s.children?.length > 0) {
          this.findExistingSection(s.children, sectionContent);
        }
      }
    });

    return foundedSection;
  }

  createNewSectionStructure(sectionsContent: SectionContent[], parent?: basicArticleSection): void {
    sectionsContent.forEach((s) => {
      let foundedSection = this.findExistingSection(this.articleSections, s);

      if (foundedSection) {
        foundedSection = JSON.parse(JSON.stringify(foundedSection));

        const articleSection = this.sharedService.YdocService.getSectionByID(
          foundedSection.sectionID
        );

        const editorContainer = this.editors[foundedSection.sectionID];
        editorContainer.editorID = s.sectionID;
        this.editors[s.sectionID] = editorContainer;

        const xmlFragment = this.xmlFragments[foundedSection.sectionID];
        this.xmlFragments[s.sectionID] = xmlFragment;
        this.newSectionStructureFlat.push(s.sectionID);

        const basicSection = getSectionBasicStructure(this.sharedService.YdocService)(
          articleSection
        );

        if (s.subsections) {
          this.createNewSectionStructure(s.subsections, basicSection);
        }

        if (!parent) {
          this.newSectionStructure.push(basicSection);
        } else {
          parent.children.push(basicSection);
        }
      } else if (this.importJatsService.generalSection) {
        const div = document.createElement('div');
        const generalSection = JSON.parse(JSON.stringify(this.importJatsService.generalSection));

        generalSection.sectionID = s.sectionID;
        generalSection.title.label = s.parsedSecTitle;

        this.sharedService.ProsemirrorEditorsService.renderBasicEditorFromImport(
          div,
          s.sectionID,
          generalSection
        );

        this.newSectionStructureFlat.push(s.sectionID);
        this.sharedService.YdocService.articleSectionsMap.set(s.sectionID, generalSection);

        const basicSection = getSectionBasicStructure(this.sharedService.YdocService)(
          generalSection
        );

        if (s.subsections) {
          this.createNewSectionStructure(s.subsections, basicSection);
        }

        if (!parent) {
          this.newSectionStructure.push(basicSection);
        } else {
          parent.children.push(basicSection);
        }
      }
    });
  }

  parseAllSections(sectionsContent: SectionContent[]): void {
    sectionsContent.forEach((section) => {
      const { doc } = section;

      if (
        section.originalSectionName == '[AM] Title' &&
        this.sharedService.titleControl.value == 'Untitled' &&
        doc.textContent?.trim()
      ) {
        this.sharedService.titleControl.setValue(
          doc.textContent.replace(this.sharedService.escapeHtmlTags, '')
        );
      }

      if (doc) {
        const editor = this.editors[section.sectionID];

        if (editor) {
          const xmlFragment = this.xmlFragments[section.sectionID];
          updateYFragment(this.sharedService.YdocService.ydoc, xmlFragment, doc, new Map());
        }
      }

      if (section.subsections?.length) {
        this.parseAllSections(section.subsections);
      }
    });
  }

  setCitableElementsAndContributors(): void {
    this.importJatsService.addCitableElementsToYdoc();
    this.importJatsService.inviteContributors();

    this.sharedService.ProsemirrorEditorsService.spinSpinner();
  }

  setSectionsData(): void {
    this.sharedService.YdocService.articleStructureMap.set(
      'articleSectionsStructure',
      this.newSectionStructure
    );
    this.sharedService.YdocService.articleStructureMap.set(
      'articleSectionsStructureFlat',
      this.newSectionStructureFlat
    );
  }

  buildEditorData(shouldRerender: boolean): void {
    this.newSectionStructure = [];
    this.newSectionStructureFlat = [];

    this.createNewSectionStructure(this.sections);

    this.setSectionsData();

    if (shouldRerender) {
      this.sharedService.YdocService.importSubject.next('import');
    }

    this.parseAllSections(this.sections);
  }

  addCitableElements(): void {
    this.sharedService.YdocService.ydocStateObservable.subscribe(({ event }) => {
      if (event == 'docIsBuild') {
        setTimeout(() => {
          this.sharedService.updateCitableElementsViewsAndCites();
          this.editorsRefsManager.updateRefsInEndEditorAndTheirCitations();

          const supplementaryFiles = JSON.parse(
            JSON.stringify(this.ydocService.supplementaryFilesMap.get('supplementaryFiles'))
          );

          const supplementaryFilesNumbers = JSON.parse(
            JSON.stringify(this.ydocService.supplementaryFilesMap.get('supplementaryFilesNumbers'))
          );

          this.citableElementsService.writeElementDataGlobal(
            supplementaryFiles,
            supplementaryFilesNumbers,
            'supplementary_file_citation'
          );
          const endNotes = JSON.parse(JSON.stringify(this.ydocService.endNotesMap.get('endNotes')));

          const endNotesNumbers = JSON.parse(
            JSON.stringify(this.ydocService.endNotesMap.get('endNotesNumbers'))
          );

          this.citableElementsService.writeElementDataGlobal(
            endNotes,
            endNotesNumbers,
            'end_note_citation'
          );
          this.changeDetectionRef.detectChanges();
          this.sharedService.ProsemirrorEditorsService.stopSpinner();
        }, 1500);
      }
    });
  }

  cancelImport(): void {
    this.dialogRef.close(undefined);
    this.importJatsService.setEmptyCitableElements();
    this.sharedService.ProsemirrorEditorsService.stopSpinner();
  }
}
