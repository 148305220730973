import { Injectable } from '@angular/core';

import {
  citableElementWithStaticViews,
  citationElementMap,
  Citations,
  ElementsObjects,
  ElementsTypeToCitationMap,
} from '@app/editor/services/citable-elements.models';
import {
  citationShouldBeIgnored,
  getCitationIfAny,
} from '@app/editor/utils/citableElementsHelpers';
import { EditorContainersMap } from './prosemirror-editor/prosemirror.models';

@Injectable({
  providedIn: 'root',
})
export class HelperCitableElementsService {
  getViewsOnCitation(
    citedElementsIds: string[],
    citaitonType: string,
    elementsNumbersObj: {
      figure: string[];
      table: string[];
    },
    displayedElementsObj: { [key: string]: boolean[] },
    citationsInElementsObj: ElementsObjects,
    displayedElsOnCurrCitation: { elId: string; type: string }[]
  ): void {
    const filteredElementIDs = [];
    // filter elements ids that repeat
    citedElementsIds.forEach((el) => {
      if (!filteredElementIDs.includes(el)) {
        filteredElementIDs.push(el);
      }
    });
    this.calcDisplayedViewsInCitation(
      filteredElementIDs,
      citaitonType,
      elementsNumbersObj,
      displayedElementsObj,
      citationsInElementsObj,
      displayedElsOnCurrCitation
    );
  }

  calcDisplayedViewsInCitation(
    citedElementsIds: string[],
    citationType: string,
    elementsNumbersObj: {
      figure: string[];
      table: string[];
    },
    displayedElementsObj: { [key: string]: boolean[] },
    citationsInElementsObj: ElementsObjects,
    displayedElsOnCurrCitation: { elId: string; type: string }[]
  ): void {
    // dont display view of elements that should be shown only in the end editor
    if (citableElementWithStaticViews.includes(ElementsTypeToCitationMap[citationType])) {
      return;
    }
    // loops all the view that are cited on the passed citation but are not displayed and add these views in displayedElsOnCurrCitation
    // calcs the views nested in one another in order
    const elsOfCurrTypeNumbers = elementsNumbersObj[citationType] as string[];
    const elsDisplayedOfCurrType = displayedElementsObj[citationType] as boolean[];
    const citationsInElsOfCurrType = citationsInElementsObj[citationType];

    let maxIndex = -1;
    citedElementsIds.forEach((el) => {
      const i = elsOfCurrTypeNumbers.indexOf(el);
      if (i > maxIndex) {
        maxIndex = i;
      }
    });

    const allCitedElements: string[] = [];
    for (let i = 0; i <= maxIndex; i++) {
      const elId = elementsNumbersObj[citationType][i];
      allCitedElements.push(elId);
    }

    const notDisplayedElsIds: string[] = [];

    allCitedElements.forEach((elId, i) => {
      if (!elsDisplayedOfCurrType[i]) {
        notDisplayedElsIds.push(elId);
      }
    });

    // mark each el that is not displayed for display on the curr citation and loop all citation on the element
    notDisplayedElsIds.forEach((elId) => {
      const elIndex = elsOfCurrTypeNumbers.indexOf(elId);
      if (!elsDisplayedOfCurrType[elIndex]) {
        displayedElsOnCurrCitation.push({ elId, type: citationType });
        elsDisplayedOfCurrType[elIndex] = true;
        const citationsOnEl = citationsInElsOfCurrType[elId];
        if (citationsOnEl) {
          citationsOnEl.forEach((citation) => {
            const citedElsInCitation = citation.citedElements;
            const citationTypeInner = citation.type;
            this.calcDisplayedViewsInCitation(
              citedElsInCitation,
              citationTypeInner,
              elementsNumbersObj,
              displayedElementsObj,
              citationsInElementsObj,
              displayedElsOnCurrCitation
            );
          });
        }
      }
    });
  }

  getElementsCitations(editors: EditorContainersMap, dontIgnore?: true): Citations {
    const citations: Citations = {};

    Object.keys(editors).forEach((sectionid) => {
      const view = editors[sectionid].editorView;
      if (!citations[sectionid]) {
        citations[sectionid] = {};
      }
      view.state.doc.nodesBetween(0, view.state.doc.nodeSize - 2, (node, pos) => {
        const citationsMarks = getCitationIfAny(node);
        if (citationsMarks.length > 0) {
          const markResolvePos = view.state.doc.resolve(pos);
          //@ts-expect-error: path may not exist on markResolvePos based on its type definition
          const posPath = markResolvePos.path;

          const citationMark = citationsMarks[0];
          const citatedElements = [...citationMark.attrs.citated_elements];
          const citateid = citationMark.attrs.citateid;
          if (!citationShouldBeIgnored(posPath) || dontIgnore) {
            const citationType = citationMark.type.name;
            citations[sectionid][citateid] = {
              displayedViewsHere: [],
              citedElementsIDs: citatedElements,
              position: pos,
              citationType: citationElementMap[citationType].type,
            };
          }
        }
      });
    });

    return citations;
  }
}
