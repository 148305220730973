import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

export function createFocusPlugin(options: {
  editor: { isEditable: boolean; isFocused: boolean };
  mode: 'deepest' | 'shallowest';
  className: string;
}) {
  return new Plugin({
    key: new PluginKey('focus'),
    props: {
      decorations: ({ doc, selection }) => {
        const { isEditable, isFocused } = options.editor;
        const { anchor } = selection;
        const decorations: Decoration[] = [];

        if (!isEditable || !isFocused) return DecorationSet.create(doc, []);

        let maxLevels = 0;
        if (options.mode === 'deepest') {
          doc.descendants((node, pos) => {
            if (!node.isText && anchor >= pos && anchor <= pos + node.nodeSize - 1) {
              maxLevels += 1;
            }
          });
        }

        let currentLevel = 0;
        doc.descendants((node, pos) => {
          if (node.isText) return false;
          const isCurrent = anchor >= pos && anchor <= pos + node.nodeSize - 1;
          if (!isCurrent) return false;

          currentLevel += 1;

          const outOfScope =
            (options.mode === 'deepest' && maxLevels - currentLevel > 0) ||
            (options.mode === 'shallowest' && currentLevel > 1);

          if (outOfScope) return options.mode === 'deepest';

          decorations.push(Decoration.node(pos, pos + node.nodeSize, { class: options.className }));
        });

        return DecorationSet.create(doc, decorations);
      },
    },
  });
}
