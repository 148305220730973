import { Injectable } from '@angular/core';
import { TextSelection, Selection } from 'prosemirror-state';
import { ResolvedPos, Slice, Node, Schema } from 'prosemirror-model';
import { EditorView } from 'prosemirror-view';
import { uuidv4 } from 'lib0/random';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { CellSelection } from 'prosemirror-tables';
import { ArticleSection } from '../../interfaces/articleSection';
import { commentMarkNames } from '../../commentsService/comments.service';
import {
  Options,
  UpdateNodeMarksResult,
  ParagraphNodeInfo,
} from './editor-props-handler.models.ts';
import { EditorContainersMap } from '@app/editor/services/prosemirror-editor/prosemirror.models';

@Injectable({
  providedIn: 'root',
})
export class EditorPropsHandlerService {
  constructor() {}

  transformPastedHTML(html: string): string {
    if (html.includes('wikipedia.org')) {
      return html.replace(/Jump up to:/gm, '');
    }
    return html;
  }

  handlePaste(sharedService: ServiceShare, options?: Options) {
    return (view: EditorView, event: ClipboardEvent, slice: Slice) => {
      if (options?.path == 'tableContent') {
        return this.handleTableContentPaste(view, event);
      }

      let newPastedCitation = false;
      let newPastedTableCitation = false;
      const selection = view.state.selection;
      const { $from } = selection;

      slice.content.nodesBetween(0, slice.size - 2, (node) => {
        const result = this.updateNodeMarks(node);
        node = result.node;
        newPastedCitation = newPastedCitation || result.newPastedCitation;
        newPastedTableCitation = newPastedTableCitation || result.newPastedTableCitation;
      });

      // Move selection to paragraph if needed
      if (this.moveSelectionToFirstParagraph(view, $from)) {
        return true; // Block paste if no paragraph found
      }

      // Check for noneditable nodes
      if (this.checkNoneditableNodes(selection)) {
        return true; // Block paste if pasting into non-editable nodes
      }

      if (newPastedCitation || newPastedTableCitation) {
        setTimeout(() => {
          sharedService.updateCitableElementsViews();
        }, 10);
      }
      return false; // Allow paste operation to proceed
    };
  }

  selectWholeCitatMarksAndRefCitatNode(
    view: EditorView,
    anchor: ResolvedPos,
    head: ResolvedPos
  ): TextSelection | undefined {
    let newSelection = false;

    let newAnchor = anchor;
    let newHead = head;

    let unbreakableNodeAtAnchor = false;
    let startOfUnbreakableNodeAtAnchor: number;
    let endOfUnbreakableNodeAtAnchor: number;
    let unbreakableNodeAtHead = false;
    let startOfUnbreakableNodeAtHead: number;
    let endOfUnbreakableNodeAtHead: number;

    if (
      (anchor.nodeAfter &&
        anchor.nodeAfter.marks.some(
          (mark) => mark.type.name === 'citation' || mark.type.name === 'table_citation'
        ) &&
        anchor.nodeBefore &&
        anchor.nodeBefore.marks.some(
          (mark) => mark.type.name === 'citation' || mark.type.name === 'table_citation'
        )) ||
      (anchor.parent &&
        anchor.parent.type.name === 'reference_citation' &&
        anchor.nodeAfter &&
        anchor.nodeBefore)
    ) {
      unbreakableNodeAtAnchor = true;

      const nodeAfterSize = anchor.nodeAfter?.nodeSize;
      const nodeBeforeSize = anchor.nodeBefore?.nodeSize;

      if (nodeAfterSize !== undefined) {
        endOfUnbreakableNodeAtAnchor = nodeAfterSize + anchor.pos;
      }

      if (nodeBeforeSize !== undefined) {
        startOfUnbreakableNodeAtAnchor = anchor.pos - nodeBeforeSize;
      }
    }

    if (
      (head.nodeAfter &&
        head.nodeAfter.marks.some(
          (mark) => mark.type.name === 'citation' || mark.type.name === 'table_citation'
        ) &&
        head.nodeBefore &&
        head.nodeBefore.marks.some(
          (mark) => mark.type.name === 'citation' || mark.type.name === 'table_citation'
        )) ||
      (head.parent &&
        head.parent.type.name === 'reference_citation' &&
        head.nodeAfter &&
        head.nodeBefore)
    ) {
      unbreakableNodeAtHead = true;

      const nodeAfterSize = head.nodeAfter?.nodeSize;
      const nodeBeforeSize = head.nodeBefore?.nodeSize;

      if (nodeAfterSize !== undefined) {
        endOfUnbreakableNodeAtHead = head.pos + nodeAfterSize;
      }

      if (nodeBeforeSize !== undefined) {
        startOfUnbreakableNodeAtHead = head.pos - nodeBeforeSize;
      }
    }

    if (unbreakableNodeAtAnchor || unbreakableNodeAtHead) {
      newSelection = true;
    }
    if (anchor.pos > head.pos) {
      if (unbreakableNodeAtAnchor) {
        newAnchor = view.state.doc.resolve(endOfUnbreakableNodeAtAnchor);
      }
      if (unbreakableNodeAtHead) {
        newHead = view.state.doc.resolve(startOfUnbreakableNodeAtHead);
      }
    } else if (anchor.pos < head.pos) {
      if (unbreakableNodeAtAnchor) {
        newAnchor = view.state.doc.resolve(startOfUnbreakableNodeAtAnchor);
      }
      if (unbreakableNodeAtHead) {
        newHead = view.state.doc.resolve(endOfUnbreakableNodeAtHead);
      }
    }

    if (newSelection) {
      return new TextSelection(newAnchor, newHead);
    }
    return undefined;
  }

  handleClick() {
    return (view: EditorView, pos: number, event: Event) => {
      const node = view.state.doc.nodeAt(pos);
      const $pos = view.state.doc.resolve(pos);
      const marks = node?.marks?.length;

      if ($pos.parent.type.name === 'doc' || $pos.parent.type.name === 'form_field') {
        return true; // Block the click
      }

      if ((event as MouseEvent).detail == 1 && !marks) {
        const newSelection = TextSelection.create(view.state.doc, pos);
        view.dispatch(view.state.tr.setSelection(newSelection));
      }

      if ((event.target as HTMLElement).className == 'changes-placeholder') {
        setTimeout(() => {
          view.dispatch(view.state.tr.setMeta('addToLastHistoryGroup', true));
        }, 0);
        return true;
      }

      return false;
    };
  }

  handleTripleClickOn(view: EditorView): boolean {
    if (view.state.selection.$from.parent.type.name !== 'form_field') {
      return true;
    }
    return false;
  }

  createSelectionBetween(editorsEditableObj: { [key: string]: boolean }, editorId: string) {
    return (view: EditorView, anchor: ResolvedPos, head: ResolvedPos) => {
      if (anchor.pos == head.pos) {
        return new TextSelection(anchor, head);
      }

      let headRangeMin = anchor.pos;
      let headRangeMax = anchor.pos;
      const selection = view.state.selection;

      //@ts-expect-error path property doesn't exist in the type definitions for the current type
      const anchorPath = selection.$anchor.path;
      let counter = anchorPath.length - 1;
      let parentNode: Node | undefined = undefined;
      let parentNodePos: number | undefined = undefined;
      let formFieldParentFound = false;

      while (counter > -1 && !formFieldParentFound) {
        const pathValue = anchorPath[counter];
        if (typeof pathValue !== 'number') {
          // node
          const parentType = pathValue.type.name;
          if (parentType == 'form_field') {
            parentNode = pathValue; // store the form_field node that the selection is currently in
            parentNodePos = anchorPath[counter - 1];
            formFieldParentFound = true;
          } else if (parentType !== 'doc') {
            parentNode = pathValue; // store last node in the path that is different than the doc node
            parentNodePos = anchorPath[counter - 1];
          }
        }
        counter--;
      }

      if (parentNode && parentNodePos !== undefined && parentNode.nodeSize !== undefined) {
        headRangeMin = parentNodePos + 1; // the parent's inner start position
        headRangeMax = parentNodePos + parentNode.nodeSize - 1; // the parent's inner end position
      }

      if (headRangeMin > head.pos || headRangeMax < head.pos) {
        const headPosition = headRangeMin > head.pos ? headRangeMin : headRangeMax;
        const newHeadResolvedPosition = view.state.doc.resolve(headPosition);
        const from = Math.min(view.state.selection.$anchor.pos, newHeadResolvedPosition.pos);
        const to = Math.max(view.state.selection.$anchor.pos, newHeadResolvedPosition.pos);
        view.state.doc.nodesBetween(from, to, (node) => {
          if (
            node.attrs.contenteditableNode == 'false' ||
            node.attrs.contenteditableNode === false
          ) {
            editorsEditableObj[editorId] = false;
          }
        });
        const newSelection = new TextSelection(anchor, newHeadResolvedPosition);
        return newSelection;
      }

      const from = Math.min(anchor.pos, head.pos);
      const to = Math.max(anchor.pos, head.pos);
      view.state.doc.nodesBetween(from, to, (node) => {
        if (node.attrs.contenteditableNode == 'false' || node.attrs.contenteditableNode === false) {
          editorsEditableObj[editorId] = false;
        }
      });
      return undefined;
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleScrollToSelection(editorContainers: EditorContainersMap, section: ArticleSection) {
    return () => {
      /*
      editorContainers[section.sectionID].containerDiv.scrollIntoView({
        behavior: "smooth", 
        block: "end", 
        inline: "nearest"
      })
      */
      return false;
    };
  }

  private handleTableContentPaste(view: EditorView, event: ClipboardEvent): boolean | undefined {
    const $head = view.state.selection.$head;
    let isInTable = false;
    for (let d = $head.depth; d > 0; d--) {
      if ($head.node(d).type.spec.tableRole == 'row') {
        isInTable = true;
      }
    }
    if (isInTable) {
      return false;
    }

    let hasTable = false;
    view.state.doc.firstChild.content.forEach((childNode) => {
      if (childNode.type.name === 'table') {
        hasTable = true;
      }
    });

    const clipboard = event.clipboardData;
    const tableData = clipboard.getData('text/html');
    const tableRegex = /<table[\s\S]*?>[\s\S]*?<\/table>/gm;
    const isTable = tableRegex.test(tableData);

    if (hasTable && isTable) {
      return true;
    }
    return !isTable;
  }

  private updateNodeMarks(node: Node): UpdateNodeMarksResult {
    let newPastedCitation = false;
    let newPastedTableCitation = false;

    if (!node.isText) {
      const citationMark = node.marks.find((mark) => mark.type.name === 'citation');
      if (citationMark) {
        const updatedCitationMark = citationMark.type.create({
          ...citationMark.attrs,
          citateid: uuidv4(),
        });
        const updatedMarks = node.marks.map((mark) =>
          mark === citationMark ? updatedCitationMark : mark
        );
        node = node.type.create(node.attrs, node.content, updatedMarks);
        newPastedCitation = true;
      }

      const tableCitationMark = node.marks.find((mark) => mark.type.name === 'table_citation');
      if (tableCitationMark) {
        const updatedTableCitationMark = tableCitationMark.type.create({
          ...tableCitationMark.attrs,
          citateid: uuidv4(),
        });
        const updatedMarks = node.marks.map((mark) =>
          mark === tableCitationMark ? updatedTableCitationMark : mark
        );
        node = node.type.create(node.attrs, node.content, updatedMarks);
        newPastedTableCitation = true;
      }

      const commentMarks = node.marks.filter((mark) => commentMarkNames.includes(mark.type.name));
      if (commentMarks.length > 0) {
        const updatedMarks = node.marks.map((mark) => {
          const updatedCommentMark = mark.type.create({
            ...mark.attrs,
            commentmarkid: uuidv4(),
          });
          return commentMarkNames.includes(mark.type.name) ? updatedCommentMark : mark;
        });

        node = node.type.create(node.attrs, node.content, updatedMarks);
      }

      if (node.type.name === 'math_inline' || node.type.name === 'math_display') {
        node = node.type.create(
          {
            ...node.attrs,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            math_id: uuidv4(),
          },
          node.content,
          node.marks
        );
      }

      const referenceCitationMark = node.marks.find(
        (mark) => mark.type.name === 'reference_citation'
      );
      if (referenceCitationMark) {
        const updatedReferenceCitationMark = referenceCitationMark.type.create({
          ...referenceCitationMark.attrs,
          refCitationID: uuidv4(),
        });
        const updatedMarks = node.marks.map((mark) =>
          mark === referenceCitationMark ? updatedReferenceCitationMark : mark
        );
        node = node.type.create(node.attrs, node.content, updatedMarks);
      }

      const linkMark = node.marks.find((mark) => mark.type.name === 'link');
      if (linkMark) {
        const updatedLinkMark = linkMark.type.create({
          ...linkMark.attrs,
          styling: '',
          title: node.textContent,
        });
        const updatedMarks = node.marks.map((mark) => (mark === linkMark ? updatedLinkMark : mark));
        node = node.type.create(node.attrs, node.content, updatedMarks);
      }
    }

    return { node, newPastedCitation, newPastedTableCitation };
  }

  private checkNoneditableNodes(selection: Selection): boolean {
    const { $from, $to } = selection;

    if ($from.depth !== $to.depth) {
      return false;
    }

    //@ts-expect-error path property doesn't exist in the type definitions for the current type
    let pathAtFrom: Array<Node | number> = $from.path;
    //@ts-expect-error path property doesn't exist in the type definitions for the current type
    let pathAtTo: Array<Node | number> = $to.path;

    if (selection instanceof CellSelection) {
      //@ts-expect-error path property doesn't exist in the type definitions for the current type
      pathAtFrom = selection.$anchorCell.path;
      //@ts-expect-error path property doesn't exist in the type definitions for the current type
      pathAtTo = selection.$headCell.path;
    }

    const parentRef = this.findRelevantParentNode(pathAtFrom, pathAtTo);

    return (
      parentRef?.attrs.contenteditableNode === 'false' ||
      parentRef?.attrs.contenteditableNode === false
    );
  }

  private findRelevantParentNode(
    pathAtFrom: Array<Node | number>,
    pathAtTo: Array<Node | number>
  ): Node | undefined {
    let parentRef: Node | undefined;

    for (let i = pathAtTo.length; i > -1; i--) {
      if (i % 3 === 0) {
        const parentFrom = pathAtFrom[i] as Node;
        const parentTo = pathAtTo[i] as Node;

        if (parentFrom === parentTo) {
          if (!parentRef) {
            parentRef = parentFrom;
          } else if (
            parentFrom.type.name === 'form_field' &&
            parentRef.type.name !== 'form_field' &&
            parentRef?.attrs.contenteditableNode !== 'false' &&
            parentRef?.attrs.contenteditableNode !== false
          ) {
            parentRef = parentFrom;
          }
        }
      }
    }

    return parentRef;
  }

  /**
   * Finds the first paragraph node within the current selection's parent node and moves the selection
   * inside it. This is useful for ensuring content operations (like paste) occur within a paragraph
   * rather than at node boundaries.
   *
   * @param view - The ProseMirror EditorView
   * @param $pos - The ResolvedPos object representing the current cursor position
   * @returns boolean - Returns true if no paragraph was found (to prevent operations), false otherwise (allow the operation to proceed)
   */
  private moveSelectionToFirstParagraph(view: EditorView, $pos: ResolvedPos): boolean {
    if ($pos.parent.type.name === 'paragraph') {
      return false; // Already in paragraph, allow operation
    }

    let paragraphNode: ParagraphNodeInfo | null = null;
    const currentNode = $pos.parent;

    currentNode.descendants((node, pos) => {
      if (node.type.name === 'paragraph' && !paragraphNode) {
        paragraphNode = { node, pos: pos + $pos.start() };
      }
    });

    if (paragraphNode) {
      const tr = view.state.tr;
      // Position + 1 moves cursor after paragraph's opening tag, ensuring content is pasted
      // within the paragraph rather than before it
      tr.setSelection(TextSelection.create(view.state.doc, paragraphNode.pos + 1));
      view.dispatch(tr);
      return false; // Found and moved to paragraph, allow operation
    }

    return true; // No paragraph found, block operation
  }

  handleTextInput(editorID: string, editorSchema: Schema): (view, from, to, text) => boolean {
    return function (view, from, to, text) {
      let delNode: any, insNode: any;
      let delNodeStartpos, delNodeEndpos, insNodeStartpos, insNodeEndpos;
      let actualDelMark: any, actualInsMark: any;
      let textnodeWithNoMarks = false;

      view.state.doc.nodesBetween(from, to, (node, pos) => {
        const delmark = node.marks.find((mark) => mark.type.name === 'deletion');
        actualDelMark = delmark;

        const insmark = node.marks.find((mark) => mark.type.name === 'insertion');
        actualInsMark = insmark;

        if (node.type.name === 'text') {
          if (delmark && !delNode) {
            delNode = node;
            delNodeStartpos = pos;
            delNodeEndpos = pos + node.nodeSize;
          } else if (insmark && !insNode) {
            insNode = node;
            insNodeStartpos = pos;
            insNodeEndpos = pos + node.nodeSize;
          } else if (node.marks.length === 0) {
            textnodeWithNoMarks = true;
          }
        }
      });

      if (!textnodeWithNoMarks && delNode && insNode) {
        setTimeout(() => {
          if (insNodeStartpos < from && from < insNodeEndpos) {
            view.dispatch(
              view.state.tr
                .setSelection(
                  TextSelection.between(
                    view.state.tr.doc.resolve(from),
                    view.state.tr.doc.resolve(insNodeEndpos),
                    -1
                  )
                )
                .replaceSelectionWith(editorSchema.text(text, [actualInsMark]))
            );
          } else if (insNodeStartpos < to && to < insNodeEndpos) {
            view.dispatch(
              view.state.tr
                .setSelection(
                  TextSelection.between(
                    view.state.tr.doc.resolve(insNodeStartpos),
                    view.state.tr.doc.resolve(to),
                    1
                  )
                )
                .replaceSelectionWith(editorSchema.text(text, [actualInsMark]))
            );
          }
        }, 100);
        return true;
      }

      return false;
    };
  }
}
